











































































import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';
import _fromPairs from 'lodash/fromPairs';
import _debounce from 'lodash/debounce';

import { firstTruthy, or } from '@/utils';
import LoadingModal from '@/components/LoadingModal.vue';
import { ANNOTATION_UI_PROPS } from '@/components/annotations/props';
import AnnotationView from './TextTranslationView.vue';
import { resolveParameters, TextTranslationAnnotationLabel } from './utils';
import {
  useInMemoryLabels,
  useTextTranslationLabels,
  useActiveText,
  useSaving,
  useHasUnsavedLabels,
  useSaveStatus
} from './functions';

export const DEBOUNCE_SAVE_TIME_MS = 500;

export default defineComponent({
  name: 'TextTranslationController',
  components: {
    AnnotationView,
    LoadingModal
  },
  props: ANNOTATION_UI_PROPS,
  setup(props) {
    const {
      assetIdToIndex,
      activeAsset,
      annotationConfiguration,
      status,
      annotations,
      layers,
      fetchMedia,
      onAnnotateAsset
    } = toRefs(props);

    const progressPercentage = computed(
      () => (status.value.annotations * 100.0) / status.value.assets
    );

    const activeAssetIndex = computed(
      () => assetIdToIndex.value[activeAsset.value.id]
    );
    const assetIdToAnnotations = computed(() => {
      return _fromPairs(annotations.value.map(ann => [ann.asset.id, ann]));
    });

    const assetId = computed(() => activeAsset.value.id);

    const {
      annotation: assetAnnotationFromServer,
      loading: loadingAssetAnnotation,
      error: errorLoadingAssetAnnotation,
      mutate: reloadAssetAnnotation,
      loadedForAssetId: assetAnnotationLoadedForAssetId
    } = layers.value.useAnnotation(assetId);

    const hasPreviousAssetAnnotationFromServer = computed(
      () => !!assetAnnotationFromServer.value
    );

    const {
      annotation: assetPredictionFromServer,
      loading: loadingAssetPrediction,
      error: errorLoadingAssetPrediction,
      loadedForAssetId: assetPredictionLoadedForAssetId
    } = layers.value.usePrediction(assetId);

    const hasPreviousAssetPredictionFromServer = computed(
      () => !!assetPredictionFromServer.value
    );

    const { labels: labelsFromServer } = useTextTranslationLabels({
      annotation: assetAnnotationFromServer
    });

    const { labels: predictionsFromServer } = useTextTranslationLabels({
      annotation: assetPredictionFromServer
    });

    const uiParameters = computed(() => {
      const parameters = annotationConfiguration.value?.parameters || {};
      return resolveParameters(parameters);
    });

    const {
      originalText,
      originalTranslation,
      loading: loadingText,
      error: errorLoadingText,
      loadedForAssetId: textLoadedForAssetId
    } = useActiveText({
      asset: activeAsset,
      fetchMedia,
      uiParameters
    });

    const {
      inMemoryLabels: labelsInMemory,
      setInMemoryLabels
    } = useInMemoryLabels({
      assetId,
      assetAnnotationFromServer,
      assetPredictionFromServer,
      assetAnnotationLoadedForAssetId: assetAnnotationLoadedForAssetId,
      assetPredictionLoadedForAssetId: assetPredictionLoadedForAssetId,
      originalTranslation,
      textLoadedForAssetId
    });

    const activeCorrectedText = computed(() => {
      const labels = labelsInMemory.value;
      if (labels.length > 0) {
        return labels[0].correctText;
      }
      return originalTranslation.value;
    });

    function setCorrectedText(text: string) {
      const newLabels = [{ correctText: text }];
      setInMemoryLabels(newLabels);
    }

    /**
     * Allow also non-assignee users to annotate until we have
     * proper review support. Backend still checks for access
     * permissions!
     */
    const canAnnotate = computed(() => true);

    const loading = or(
      computed(
        () =>
          loadingAssetAnnotation.value &&
          assetAnnotationLoadedForAssetId.value !== activeAsset.value.id
      ),
      loadingAssetPrediction,
      loadingText
    );

    const initializing = computed(() => loading.value);

    const errorLoading = firstTruthy(
      errorLoadingAssetAnnotation,
      errorLoadingAssetPrediction,
      errorLoadingText
    );

    const { saving, error: errorSaving, saveLabels } = useSaving({
      asset: activeAsset,
      onAnnotateAsset,
      reloadAssetAnnotation
    });

    const showNothingChanged = ref(false);

    const hasUnsavedLabels = useHasUnsavedLabels({
      labelsFromServer,
      labelsInMemory
    });

    const showSuccess = ref(false);

    const debouncedSave = _debounce(saveLabels, DEBOUNCE_SAVE_TIME_MS, {
      leading: false,
      trailing: true
    });

    function saveInMemoryLabels() {
      const labelList = labelsInMemory.value;
      if (hasUnsavedLabels.value) {
        debouncedSave(assetId.value, labelList);
      } else {
        showNothingChanged.value = true;
      }
    }

    const isInitialized = computed(() => !initializing.value);

    const saveStatus = useSaveStatus({
      loading,
      saving,
      errorSaving,
      isInitialized,
      hasUnsavedLabels,
      labelsFromServer
    });

    return {
      originalText,
      originalTranslation,
      activeCorrectedText,
      saving,
      errorLoading,
      errorSaving,
      initializing,
      loading,
      showSuccess,
      showNothingChanged,
      progressPercentage,
      activeAssetIndex,
      assetIdToAnnotations,
      canAnnotate,
      setCorrectedText,
      hasPreviousAssetAnnotationFromServer,
      hasPreviousAssetPredictionFromServer,
      labelsFromServer,
      predictionsFromServer,
      saveInMemoryLabels,
      saveStatus,
      hasUnsavedLabels,
      labelsInMemory,
      textLoadedForAssetId,
      assetAnnotationFromServer,
      assetAnnotationLoadedForAssetId
    };
  },
  methods: {
    nextAsset(): void {
      const index = this.activeAssetIndex;
      const newIndex = index + 1;
      if (newIndex >= this.assets.length) {
        this.onFinishAnnotating();
        return;
      }
      this.$emit('update:activeAsset', this.assets[newIndex]);
    },
    previousAsset(): void {
      const index = this.activeAssetIndex;
      const newIndex = index - 1;
      if (newIndex < 0) {
        return;
      }
      this.$emit('update:activeAsset', this.assets[newIndex]);
    },
    onNext(): void {
      this.nextAsset();
    },
    onPrev(): void {
      this.previousAsset();
    }
  }
});
