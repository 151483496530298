import { PropType } from 'vue';

import {
  AnnotateAssetFunc,
  AssetIdToIndex,
  FinishAnnotatingFunc
} from '@/components/annotations/types';

import {
  Asset,
  AnnotatedAsset,
  CompactAnnotationConfiguration,
  CompactAnnotationGuideline,
  FetchMedia,
  AnnotationProgressStatus,
  RouteUpdateListener,
  AnnotationUI
} from '@/types';
import { LayersContainer } from '@/layers';

/** Props shared by annotation UIs */
export const ANNOTATION_UI_PROPS = {
  activeAsset: {
    required: true,
    type: Object as PropType<Asset>
  },
  task: {
    required: true,
    type: Object
  },
  assets: {
    required: true,
    type: Array as PropType<Array<Asset>>
  },
  annotations: {
    type: Array as PropType<Array<AnnotatedAsset>>,
    required: true
  },
  annotationConfiguration: {
    type: Object as PropType<CompactAnnotationConfiguration>,
    required: false
  },
  annotationGuideline: {
    type: Object as PropType<CompactAnnotationGuideline>,
    required: false
  },
  annotationUi: {
    required: true,
    type: Object as PropType<AnnotationUI>
  },
  onAnnotateAsset: {
    required: true,
    type: Function as PropType<AnnotateAssetFunc>
  },
  onFinishAnnotating: {
    type: Function as PropType<FinishAnnotatingFunc>,
    required: true
  },
  fetchMedia: {
    type: Function as PropType<FetchMedia>,
    required: true
  },
  assetIdToIndex: {
    type: Object as PropType<AssetIdToIndex>,
    required: true
  },
  layers: {
    type: Object as PropType<LayersContainer>,
    required: false
  },
  status: {
    type: Object as PropType<AnnotationProgressStatus>,
    required: true
  },
  registerRouteUpdateListener: {
    type: Function as PropType<(func: RouteUpdateListener) => void>,
    required: true
  }
};
