













































































































































import Vue, { PropType } from 'vue';

import { SaveStatus, SaveStatusStrings } from '@/components/functions';
import { addLineBreaks, SaveTextTranslationAnnotationLabels } from './utils';

export default Vue.extend({
  name: 'TextTranslationView',
  props: {
    assetId: {
      required: true,
      type: String
    },
    assetIndex: {
      type: Number,
      required: true
    },
    canAnnotate: {
      type: Boolean,
      required: true
    },
    numberOfAssets: {
      type: Number,
      required: true
    },
    activeOriginalText: {
      type: String,
      required: true
    },
    activeTranslatedText: {
      type: String,
      required: true
    },
    correctText: {
      type: String,
      required: true
    },
    save: {
      required: true,
      type: Function as PropType<SaveTextTranslationAnnotationLabels>
    },
    next: {
      required: true,
      type: Function as PropType<() => void>
    },
    prev: {
      required: true,
      type: Function as PropType<() => void>
    },
    hasPreviousAssetAnnotationFromServer: {
      required: true,
      type: Boolean
    },
    hasPreviousAssetPredictionFromServer: {
      required: true,
      type: Boolean
    },
    saveStatus: {
      required: true,
      type: String as PropType<SaveStatusStrings>
    }
  },
  mounted() {
    // Adjust the text area height to match the initial text
    this.recalculateInputHeight();
    this.focusFirstSelect();
  },
  data() {
    return {
      showInputInvalid: false,
      showNothingChanged: false,
      selected: [],
      fontSizeRem: 1,
      correctTextPlaceholder: '',
      rules: [(v: string) => !!v && v.length > 0],
      inputValid: false,
      tab: 1,
      SaveStatus
    };
  },
  computed: {
    title(): string {
      return `Text Translation (${this.assetIndex + 1}/${this.numberOfAssets})`;
    },
    correctTextInput: {
      get(): string {
        return this.correctText;
      },
      set(val: string) {
        this.$emit('update:correctText', val);
        this.recalculateInputHeight();
      }
    }
  },
  methods: {
    recalculateInputHeight() {
      // wait for Vue to finish updating the DOM
      console.log(`Recalculating height`);
      Vue.nextTick(() => (this.$refs.textarea as any).calculateInputHeight());
    },
    async onClickNext() {
      await this.saveIfShould();
      this.next();
      this.scrollToTop();
    },
    async onClickPrev() {
      await this.saveIfShould();
      this.prev();
      this.scrollToTop();
    },
    onClickSkip() {
      this.next();
      this.scrollToTop();
    },
    onClickCopyPaste() {
      this.correctTextInput = addLineBreaks(this.activeTranslatedText);
      this.recalculateInputHeight();
      this.tab = 1;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    focusFirstSelect() {
      const keys = Object.keys(this.$refs).includes('select')
        ? Object.keys(this.$refs.select)
        : [];
      if (keys.length > 0) {
        setTimeout(() => this.$refs.select[0].focus(), 300);
      }
    },
    async saveIfShould() {
      if (!this.canAnnotate) {
        return;
      }

      if (!this.inputValid) {
        this.showInputInvalid = true;
        return;
      }

      this.save();
    }
  },
  filters: {
    addLineBreaks: function(val: string): string {
      return addLineBreaks(val);
    }
  },
  watch: {
    assetId: {
      handler: function() {
        console.debug(`Asset ID changed, delaying input height`);
        this.focusFirstSelect();
        setTimeout(() => this.recalculateInputHeight(), 500);
      },
      immediate: true
    }
  }
});
