import { AnnotatedAsset, Asset, FetchMedia, Label } from '@/types';
import {
  computed,
  reactive,
  ref,
  Ref,
  toRefs,
  watch
} from '@vue/composition-api';
import { until } from '@vueuse/core';
import {
  ActiveText,
  findTextMediaSlugs,
  TextTranslationAnnotationLabel,
  TextTranslationParameters
} from './utils';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';

const isTextTranslationAnnotationLabel = (
  label: Label
): label is TextTranslationAnnotationLabel => {
  return !!label.correctText;
};

export const parseLabel = (label: any): TextTranslationAnnotationLabel => {
  if (!label.correctText) {
    throw Error(
      `Missing field 'correctText' in label: ${JSON.stringify(label)}`
    );
  }

  return {
    ...label
  };
};

export function parseLabels(labels: any[]): TextTranslationAnnotationLabel[] {
  return labels
    .map(label => {
      try {
        return parseLabel(label);
      } catch (err) {
        console.warn(`Ignoring invalid label`, label);
        return undefined;
      }
    })
    .filter(label => !!label);
}

export const useTextTranslationLabels = ({
  annotation
}: {
  annotation: Ref<AnnotatedAsset | null>;
}) => {
  const labels = computed(() => {
    const assetAnnotation = annotation.value;

    if (!assetAnnotation) {
      return [];
    }

    const labels_ = parseLabels(assetAnnotation.labels);

    return labels_;
  });

  return { labels };
};

export const useActiveText = ({
  asset,
  fetchMedia,
  uiParameters
}: {
  asset: Ref<Asset>;
  fetchMedia: Ref<FetchMedia>;
  uiParameters: Ref<TextTranslationParameters>;
}) => {
  const state = reactive({
    originalText: null as string | null,
    originalTranslation: null as string | null,
    loading: false,
    error: null as Error | null,
    loadedForAssetId: null as string | null
  });

  async function resolveActiveText(): Promise<ActiveText> {
    const originalTextKey: string = uiParameters.value.originalTextAttribute;
    const translatedTextKey: string =
      uiParameters.value.translatedTextAttribute;
    const medias = asset.value.media;
    const assetId = asset.value.id;

    const keys = findTextMediaSlugs(medias);
    if (
      !(
        Object.keys(keys).includes(originalTextKey) &&
        Object.keys(keys).includes(translatedTextKey)
      )
    ) {
      throw new Error(
        `Could not resolve text from asset: ${JSON.stringify(asset.value.id)}.
          Do the config values for originalText and translatedText match those in the
          dataset? Searched from ${originalTextKey} and ${translatedTextKey} from available text fields: ${Object.keys(
          keys
        ).join(', ')}`
      );
    }
    const originalTextAssetMedia = medias[originalTextKey];
    const translatedTextAssetMedia = medias[translatedTextKey];

    console.log(
      `Fetching media: ${originalTextKey} of type ${originalTextAssetMedia.media_type}`
    );

    const originalTextMedia = await fetchMedia.value({
      assetId: assetId,
      mediaSlug: originalTextKey,
      mediaType: originalTextAssetMedia.media_type
    });

    console.log(
      `Fetching media: ${translatedTextKey} of type ${translatedTextAssetMedia.media_type}`
    );
    const translatedTextMedia = await fetchMedia.value({
      assetId,
      mediaSlug: translatedTextKey,
      mediaType: translatedTextAssetMedia.media_type
    });

    return {
      originalText: new TextDecoder().decode(originalTextMedia),
      translatedText: new TextDecoder().decode(translatedTextMedia)
    };
  }

  async function mutate() {
    state.error = null;

    if (!asset.value || !uiParameters.value) {
      state.loadedForAssetId = null;
      state.originalText = '';
      state.originalTranslation = '';
      return;
    }

    state.loading = true;

    try {
      const activeText = await resolveActiveText();
      state.originalText = activeText.originalText;
      state.originalTranslation = activeText.translatedText;
      state.loadedForAssetId = asset.value.id;
    } catch (err) {
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([asset, uiParameters], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useInMemoryLabels = ({
  assetId,
  assetAnnotationFromServer,
  assetPredictionFromServer,
  assetPredictionLoadedForAssetId,
  assetAnnotationLoadedForAssetId,
  originalTranslation,
  textLoadedForAssetId
}: {
  assetId: Ref<string | null>;
  assetAnnotationFromServer: Ref<AnnotatedAsset | null>;
  assetPredictionFromServer: Ref<AnnotatedAsset | null>;
  assetPredictionLoadedForAssetId: Ref<string | null>;
  assetAnnotationLoadedForAssetId: Ref<string | null>;
  originalTranslation: Ref<string>;
  textLoadedForAssetId: Ref<string | null>;
}) => {
  const inMemoryLabels = ref([] as TextTranslationAnnotationLabel[]);

  function mutateFromServer() {
    const initializationAnnotation =
      assetAnnotationFromServer.value || assetPredictionFromServer.value;

    console.debug(`Original translation`, originalTranslation.value);

    const newLabels = parseLabels(
      initializationAnnotation?.labels || [
        { correctText: originalTranslation.value }
      ]
    );

    inMemoryLabels.value = newLabels;
  }

  function setInMemoryLabels(labels: TextTranslationAnnotationLabel[]) {
    inMemoryLabels.value = labels;
  }

  watch(
    assetId,
    async function() {
      // Asset ID changed, need to refresh from server once those are loaded
      await until(assetAnnotationLoadedForAssetId).toBe(assetId.value);
      await until(assetPredictionLoadedForAssetId).toBe(assetId.value);
      await until(textLoadedForAssetId).toBe(assetId.value);

      mutateFromServer();
    },
    { immediate: true }
  );

  return { inMemoryLabels, setInMemoryLabels };
};

export const useSaving = ({
  asset,
  onAnnotateAsset,
  reloadAssetAnnotation
}: {
  asset: Ref<Asset>;
  onAnnotateAsset: Ref<any>;
  reloadAssetAnnotation: () => Promise<void>;
}) => {
  const state = reactive({
    saving: false,
    error: null as Error | null
  });

  async function saveLabels(
    assetId: string,
    labels: TextTranslationAnnotationLabel[]
  ): Promise<void> {
    console.log('Saving new labels', labels);

    state.saving = true;
    state.error = null;
    try {
      await onAnnotateAsset.value({
        assetId,
        labels
      });
      if (assetId === asset.value.id) {
        reloadAssetAnnotation();
      }
    } catch (err) {
      state.error = err;
    } finally {
      state.saving = false;
    }
  }

  return { ...toRefs(state), saveLabels };
};

export const useHasUnsavedLabels = ({
  labelsFromServer,
  labelsInMemory
}: {
  labelsFromServer: Ref<TextTranslationAnnotationLabel[]>;
  labelsInMemory: Ref<TextTranslationAnnotationLabel[]>;
}) => {
  return computed(() => {
    const serverLabelsWithoutTraceIds = labelsFromServer.value.map(ann =>
      _omit(ann, 'trace_id')
    );
    const sortedServerLabels = _sortBy(serverLabelsWithoutTraceIds, 'feature');
    const sortedInMemoryLabels = _sortBy(labelsInMemory.value, 'feature');

    return !_isEqual(sortedServerLabels, sortedInMemoryLabels);
  });
};

export const SaveStatus = {
  Saved: 'Saved',
  Saving: 'Saving',
  Empty: 'Empty',
  UnsavedChanges: 'UnsavedChanges'
} as const;

export type SaveStatusStrings = keyof typeof SaveStatus;

export const useSaveStatus = ({
  hasUnsavedLabels,
  isInitialized,
  errorSaving,
  saving,
  loading,
  labelsFromServer
}: {
  hasUnsavedLabels: Ref<boolean>;
  isInitialized: Ref<boolean>;
  errorSaving: Ref<Error | null>;
  saving: Ref<boolean>;
  loading: Ref<boolean>;
  labelsFromServer: Ref<TextTranslationAnnotationLabel[]>;
}) => {
  return computed(() => {
    return !isInitialized.value || loading.value || errorSaving.value
      ? SaveStatus.Empty
      : saving.value
      ? SaveStatus.Saving
      : hasUnsavedLabels.value || labelsFromServer.value.length === 0
      ? SaveStatus.UnsavedChanges
      : SaveStatus.Saved;
  });
};
